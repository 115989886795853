import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbAccordionModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonFeatureModule } from "../common/common-feature.module";
import { IssueCouponModalComponent } from "./issue-coupon-modal/issue-coupon-modal.component";
@NgModule({
  declarations: [IssueCouponModalComponent],
  imports: [CommonFeatureModule, BrowserModule, BrowserAnimationsModule, NgbAccordionModule],
  exports: [IssueCouponModalComponent]
})
export class IssueCouponModule {}
