import { Injectable } from "@angular/core";
import { AccountService } from "@app-cmc/core/services/account.service";
import { DataService } from "@app-cmc/core/services/data.service";
import { Recipient, RecipientAllowedType } from "@app-cmc/features/common";
import { CouponInfo } from "@app-cmc/models/coupons/coupon-info";
import { CompanyDataService } from "@app-cmc/services/company-data.service";
import { ToastService } from "@app-cmc/shared";
import { TranslocoService } from "@ngneat/transloco";
import { Observable, throwError } from "rxjs";
import { map, tap } from "rxjs/operators";
import {
  AllowedDeliveryChannel,
  DeliverySource,
  FeatureTypes,
  RecipientProfile,
  WidgetMessageInfo,
  WidgetMessagePostDto,
  WidgetMessageResultDto
} from "../models";
import { FeaturesService } from "./features.service";

export type WidgetConfirmType = "send" | "generateQrCode" | "sendSMS" | "sendEmail";

export interface InitialWidgetMessageInfo {
  deliveryMethod: AllowedDeliveryChannel;
  recipients?: Recipient[];
  sessionId?: string;
}

export interface WidgetMessageGetInfo {
  messageType: "start" | "complete";
  widgetAction?: WidgetConfirmType;
  featureId?: FeatureTypes;
  messageInfo: WidgetMessageInfo;
  data?: any;
}

interface MapRecipientData {
  campaignId: number;
  recipient: RecipientProfile;
  deliveryMethod: AllowedDeliveryChannel;
  deliverySource: DeliverySource;
  sessionId: string;
  data?: any;
}

@Injectable({ providedIn: "root" })
export class WidgetService {
  constructor(
    private http: DataService,
    private accSvc: AccountService,
    private featSvc: FeaturesService,
    private toastSvc: ToastService,
    private transloco: TranslocoService,
    private companyDataService: CompanyDataService
  ) {}

  private serviceUrl = "widget";

  private static getCouponWidgetData(coupon: CouponInfo): any {
    return {
      CouponImageUrl: coupon.couponImage,
      CouponUrl: coupon.url,
      CouponName: coupon.name,
      CouponOffer: coupon.title
    };
  }

  sendStartMessage(message: WidgetMessagePostDto): Observable<WidgetMessageResultDto> {
    return this.http.post(`${this.serviceUrl}/start`, message);
  }

  sendCompleteMessages(messages: WidgetMessagePostDto[]): Observable<WidgetMessageResultDto> {
    return this.http
      .post(`${this.serviceUrl}/complete`, messages)
      .pipe(map((data: WidgetMessageResultDto[]) => (data?.length ? data[0] : null)));
  }

  getRecipientAllowType(featureId: FeatureTypes): RecipientAllowedType {
    const campaign = this.featSvc.getWidgetByFeatureId(featureId);
    const deliveryMethod = campaign.deliveryMethod;
    switch (deliveryMethod) {
      case AllowedDeliveryChannel.email:
        return "email";
      case AllowedDeliveryChannel.sms:
        return "phone";
      case AllowedDeliveryChannel.emailAndSms:
        return "phoneOrEmail";

      default:
        return null;
    }
  }

  getWidgetMessageInfo({ deliveryMethod, recipients = [new Recipient()], sessionId = "" }: InitialWidgetMessageInfo): WidgetMessageInfo {
    if (!recipients?.length) {
      recipients = [new Recipient()];
    }

    const profileRecipients: RecipientProfile[] = recipients.map((item: Recipient) => {
      const profile = new RecipientProfile();
      return {
        ...profile,
        customerEmail: item.email || "",
        customerPhone: item.phone || "",
        firstName: item.firstName || "",
        lastName: item.lastName || "",
        purchaseName: item.purchaseName || "",
        purchaseId: (item.purchaseId && item.purchaseId.toString()) || "",
        purchaseCategory: item.purchaseCategory || "",
        purchasePrice: (item.purchasePrice && item.purchasePrice.toString()) || ""
      };
    });

    return {
      recipients: profileRecipients,
      deliveryMethod,
      deliverySource: DeliverySource.Cmc,
      sessionId
    };
  }

  getWidgetMessage(getInfo: WidgetMessageGetInfo): Observable<WidgetMessageResultDto> {
    const { messageType, featureId, messageInfo, data } = getInfo;
    const widgetAction = getInfo.widgetAction || "generateQrCode";
    const widget = this.featSvc.getWidgetByFeatureId(featureId);
    if (!widget) {
      return throwError({ error: { message: `Template for feature id ${featureId} not exist!` } });
    }

    const { deliveryMethod, deliverySource, sessionId } = messageInfo;
    const messages: WidgetMessagePostDto[] = messageInfo?.recipients?.map((recipient: RecipientProfile) =>
      this.mapRecipient({
        campaignId: widget.id,
        recipient,
        deliveryMethod,
        deliverySource,
        sessionId,
        data
      })
    );

    return messageType === "start"
      ? this.sendStartMessage(messages[0])
      : this.sendCompleteMessages(messages).pipe(
          tap((message) => {
            if (widgetAction === "send") {
              this.showSuccessSentMessage(messageInfo.recipients);
              this.companyDataService.retriveData();
            }
          })
        );
  }

  getCouponWidgetMessage(getInfo: WidgetMessageGetInfo, coupon: CouponInfo): Observable<WidgetMessageResultDto> {
    const { messageType, messageInfo } = getInfo;
    const deliveryMethod: AllowedDeliveryChannel = messageInfo.deliveryMethod;
    const data = WidgetService.getCouponWidgetData(coupon);

    return this.getWidgetMessage({
      widgetAction: "generateQrCode",
      messageType: messageType,
      featureId: FeatureTypes.IssueCoupon,
      messageInfo,
      data
    }).pipe(
      tap(() => {
        if (messageType === "complete" && deliveryMethod !== AllowedDeliveryChannel.qr) {
          this.showSuccessSentMessage(messageInfo.recipients);
        }
      })
    );
  }

  getMessageCount(recipients: RecipientProfile[]) {
    let count = 0;
    recipients.forEach((message) => {
      count += !message.customerPhone || !message.customerEmail ? 1 : 2;
    });

    return count;
  }

  private mapRecipient({ campaignId, recipient, deliveryMethod, sessionId, data = {} }: MapRecipientData) {
    recipient.salesPerson = this.accSvc.user.name;
    const msgData = new WidgetMessagePostDto();
    const locId = this.accSvc.user.locationId;

    msgData.locationId = +locId;
    msgData.campaignId = campaignId;
    msgData.profile = recipient;
    msgData.data = data;
    msgData.deliveryMethod = deliveryMethod;
    msgData.deliverySource = null;
    msgData.sessionId = sessionId;
    return msgData;
  }

  private showSuccessSentMessage(recipients: RecipientProfile[]): void {
    const count = this.getMessageCount(recipients);
    const message = this.transloco.translate("common.message.successSent", { count });

    this.toastSvc.success(message);
  }
}
