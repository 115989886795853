<div class="validate-coupon-modal" *transloco="let t; read: 'features.validateCoupon'">
  <div class="modal-header">
    <h5 class="modal-title">{{ t("title") }}</h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="dismiss()"></button>
  </div>

  <div class="modal-body">
    <div>
      <ng-container *ngIf="!loading; else spinner">
          <div class="content">
            <form class="modal-form" [formGroup]="form" *transloco="let terror; read: 'features.common'">
              <app-input
                [control]="form.controls.code"
                [isRequired]="true"
                [placeholder]="t('searchCoupon')"
                [icon]="'mdi mdi-magnify cursor-pointer'"
                (iconClick)="search()"
                (keyup.enter)="search()">
              </app-input>
            </form>

            <ng-container *ngIf="!loading">
              <div *ngIf="invalidCoupon$ | async; else couponIsValid" class="search-empty-block">
                <span class="content-text" *transloco="let t; read: 'features.issueCoupon'">
                  {{ t("nothing_found") }}
                </span>
              </div>

              <ng-template #couponIsValid>
                <div *ngIf="!coupon" class="search-block">
                  <img alt="" class="search-block__img" src="assets/images/load-img.svg" />

                  <div class="search-block__text">{{ t("search_text") }}</div>
                </div>
              </ng-template>
            </ng-container>
          </div>

          <div class="content validate-coupon-block" *ngIf="coupon">
            <app-widget-message
              *ngIf="isValidated || isCouponUsed"
              class="validate-message"
              [type]="isValidated ? 'success' : 'fail'"
              [successMessage]="'features.validateCoupon.validated' | transloco"
              [failMessage]="'features.validateCoupon.used' | transloco">
            </app-widget-message>

            <div class="valid-coupon">
              <div class="valid-coupon__column valid-coupon__img-content">
                <ng-template #couponpopContent>
                  <div [innerHtml]="coupon.description"></div>
                </ng-template>

                <div class="valid-coupon__img-wrapper">
                  <img
                    alt=""
                    class="valid-coupon__img"
                    width="100%"
                    placement="right"
                    container="body"
                    [ngbPopover]="couponpopContent"
                    [popoverTitle]="t('description')"
                    triggers="mouseenter:mouseleave"
                    [src]="coupon.couponImage"
                  />
                </div>
              </div>

              <app-validate-coupon-details
                class="valid-coupon__column valid-coupon__content"
                [isUsed]="isCouponUsed"
                [coupon]="coupon"
                [couponStatus]="couponStatus"
              >
              </app-validate-coupon-details>
            </div>

            <app-validate-coupon-additional-details class="valid-coupon__content" [user]="user"> </app-validate-coupon-additional-details>
          </div>
      </ng-container>
    </div>

    <div class="modal-footer inner two-actions" *transloco="let t; read: 'common.controls'">
      <app-qr-code-reader-button
        class="btn p-0"
        [buttonText]="'qrcode.scan' | transloco"
        (scanModalOpened)="onScanModalOpened()"
        (scanResult)="scan($event)">
      </app-qr-code-reader-button>

      <button
        type="button"
        class="btn btn-main"
        [ngbTooltip]="t('validate')"
        [class.disabled]="!coupon || !codeControl.valid || loading || isCouponUsed"
        #tooltip="ngbTooltip"
        (click)="validate(); tooltip.close()"
      >
        {{ t("validate") }}
      </button>
    </div>

    <ng-template #spinner>
      <div class="spinner-wrapper">
        <span class="spinner-border spinner-border-sm mr-1"> </span>
      </div>
    </ng-template>
  </div>
</div>
