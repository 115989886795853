import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbModalModule, NgbPopoverModule, NgbToastModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { FormComponentsModule, WizardComponent } from "ui-kit";
import { ConfirmModalComponent, NotificationMessageComponent, SpinnerComponent, TileComponent } from "./components";
import { AccessDeniedComponent } from "./components/access-denied/access-denied.component";
import { ToasterComponent } from "./components/app-toaster/";
import { PendingConfigurationComponent } from "./components/pending-configuration/pending-configuration.component";
import { StepHeaderComponent } from "./components/step-wizard/step-header/step-header.component";
import { StepWizardComponent } from "./components/step-wizard/step-wizard.component";
import { MatchBitMaskPipe } from "./pipes/match-bit-mask.pipe";
import { UIModule } from "./ui/ui.module";
import { WidgetModule } from "./widget/widget.module";

const maskConfig: Partial<IConfig> = {
  validation: false
};

@NgModule({
  declarations: [
    SpinnerComponent,
    TileComponent,
    ConfirmModalComponent,
    ToasterComponent,
    StepWizardComponent,
    StepHeaderComponent,
    AccessDeniedComponent,
    PendingConfigurationComponent,
    MatchBitMaskPipe,
    NotificationMessageComponent
  ],
  imports: [
    NgxMaskModule.forRoot(maskConfig),
    CommonModule,
    NgbModalModule,
    NgbTooltipModule,
    NgbToastModule,
    NgbPopoverModule,
    UIModule,
    FormComponentsModule,
    WidgetModule,
    TranslocoModule,
    ReactiveFormsModule,
    WizardComponent
  ],
  exports: [
    NgbModalModule,
    NgbToastModule,
    NgbTooltipModule,
    NgbPopoverModule,
    FormComponentsModule,
    // Components
    SpinnerComponent,
    TileComponent,
    ConfirmModalComponent,
    ToasterComponent,
    StepWizardComponent,
    // Pipes
    MatchBitMaskPipe,
    // Notification
    NotificationMessageComponent,
    TranslocoModule,
    WizardComponent
  ]
})
export class SharedModule {}
