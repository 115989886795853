import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "@app-cmc/core";
import { FeatureModalBaseComponent } from "@app-cmc/features/feature-modal-base.component";
import {
  FeatureTypes,
  Location,
  LoyaltyCard,
  LoyaltyCardReward,
  LoyaltyCardUpdateAmount,
  LoyaltyCardUpdateResult,
  LoyaltyCardUser,
  LoyaltyCardUsersSearchDto,
  LoyaltyCardUsersSearchResultDto
} from "@app-cmc/models";
import { CFSConnectionService, LocationService, LoyaltyCardService } from "@app-cmc/services";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoService } from "@ngneat/transloco";
import { NULL_OBSERVABLE } from "clearline-api";
import { RewardCardView, rewardsDtoToRewardCardList } from "clearline-common";
import { forkJoin, Observable, of, Subject } from "rxjs";
import { auditTime, catchError, distinctUntilChanged, filter, finalize, map, switchMap, take, takeUntil, tap } from "rxjs/operators";

@Component({
  selector: "app-issue-points-modal",
  templateUrl: "./issue-points-modal.component.html",
  styleUrls: ["./issue-points-modal.component.scss"]
})
export class IssuePointsModalComponent extends FeatureModalBaseComponent implements OnInit, OnDestroy {
  readonly locationId = +this.accountService.user.locationId;
  isValidated = false;
  isUsed = false;
  isAutoSearch = false;
  loading = false;
  nothingFound = false;
  serverError: string;

  points: FormControl;
  form: FormGroup;

  loyaltyCard: LoyaltyCard;
  loyaltyUser: LoyaltyCardUser;
  loyaltyUserOptions$: Observable<LoyaltyCardUser[]>;
  selectedCard: LoyaltyCard;
  rewards: Array<LoyaltyCardReward>;
  activeRewards: RewardCardView[] = [];
  balanceLabel = "";
  balanceValue = 0;
  searchInput$ = new Subject<string>();

  get isValid() {
    return this.form.valid;
  }

  get cardSelected() {
    return !!this.selectedCard;
  }

  get searchControl(): FormControl {
    return this.form.controls.search as FormControl;
  }

  private loyaltyUsers: LoyaltyCardUser[] = [];
  private searchMinLength = 3;
  private currentLocation: Location;
  private unsubscribe$ = new Subject();

  constructor(
    public activeModal: NgbActiveModal,
    public accountService: AccountService,
    private locationService: LocationService,
    public translateSvc: TranslocoService,
    public toastSvc: ToastService,
    private loyaltyCardService: LoyaltyCardService,
    private cfsService: CFSConnectionService
  ) {
    super(activeModal, FeatureTypes.IssuePoints);
  }

  ngOnInit(): void {
    this.setForms();
    this.setLoyaltyCards();
  }

  onScanModalOpened(): void {
    this.cfsService.toggleScanQrCodeModal(true).pipe(take(1)).subscribe();
  }

  scan(userCardLink: string) {
    this.onScanModalClose();
    const invalidUrlMessage = this.translateSvc.translateObject("features.issuePoints.notValidUrl");

    if (!userCardLink || !userCardLink.startsWith("http")) {
      this.toastSvc.danger(invalidUrlMessage);
      return;
    }

    this.loyaltyCard ??= this.selectedCard; // fixme (the same?)

    const params = userCardLink.split("/");
    const userCode = params.pop();
    const cardCode = params.pop();
    const card = this.loyaltyCard.code === cardCode ? this.loyaltyCard : null;

    if (!card) {
      this.toastSvc.danger(invalidUrlMessage);
      return;
    }

    this.onSelectChanged(card);
    this.searchByCode(userCode);
  }

  search() {
    const searchText = this.searchControl?.value?.trim();

    this.resetBeforeSearch();
    this.resetPointsForm();
    this.loading = true;

    this.getLoyaltyCardUsers(searchText)
      .pipe(take(1))
      .subscribe(
        () => {
          this.loading = false;
          this.loyaltyUser = this.loyaltyUsers.length ? this.loyaltyUsers[0] : null;
        },
        () => {
          this.loading = false;
          this.nothingFound = true;
        }
      );
  }

  submit() {
    this.serverError = null;
    this.loading = true;
    const location = this.loyaltyCard.locations.find((l) => l.name === this.currentLocation.companyName);

    if (location) {
      const payload: LoyaltyCardUpdateAmount = {
        merchantid: `${location.id}`,
        amount: this.points.value,
        user: this.loyaltyUser.id,
        loyaltycard: this.loyaltyCard.id,
        locationId: this.currentLocation.id
      };

      this.loyaltyCardService
        .addUserLoyaltyCardAmount(payload)
        .pipe(
          take(1),
          finalize(() => (this.loading = false))
        )
        .subscribe(
          (result: LoyaltyCardUpdateResult) => {
            this.loading = false;

            if (result?.loyaltycard) {
              const targetI18nKey = this.loyaltyCard.type === "stampcard" ? "loyaltyProgram.stamps" : "loyaltyProgram.points";
              const target = this.translateSvc.translate(targetI18nKey);

              this.isUsed = true;
              this.loyaltyCard.type === "stampcard"
                ? (this.loyaltyUser.stamps = result.newAmount)
                : (this.loyaltyUser.points = result.newAmount);
              this.rewards = result.rewardsAdded;

              this.updateUserBalance();
              this.toastSvc.success(
                this.translateSvc.translate("loyaltyProgram.addSuccessMessage", {
                  value: result.addedAmount,
                  target
                })
              );
            } else {
              this.toastSvc.danger(this.translateSvc.translate("loyaltyProgram.errorCardMessage"));
            }
          },
          (error) => {
            this.serverError = error.error.Message;
          }
        );
    } else {
      this.loading = false;

      this.toastSvc.danger(this.translateSvc.translate("loyaltyProgram.errorLocationMessage"));
    }
  }

  onSelectChanged(loyaltyCard: LoyaltyCard) {
    this.selectedCard = loyaltyCard;
    this.updateUserBalance();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateUserBalance(): void {
    if (this.loyaltyUser) {
      this.balanceLabel = this.selectedCard.amountOfStamps ? "stamps" : "points";
      this.balanceValue = this.selectedCard.type === "stampcard" ? this.loyaltyUser.stamps : this.loyaltyUser.points;
      this.activeRewards = rewardsDtoToRewardCardList(this.loyaltyUser.activeRewards);
    }
  }

  private onScanModalClose(): void {
    this.cfsService
      .toggleScanQrCodeModal(false)
      .pipe(take(1))
      .subscribe(() => {});
  }

  private resetBeforeSearch(): void {
    this.serverError = null;
    this.nothingFound = false;
  }

  private setForms(): void {
    this.points = new FormControl(null, [Validators.required]);
    this.form = new FormGroup({
      search: new FormControl("", [Validators.required, Validators.minLength(this.searchMinLength)])
    });

    this.handleFormsValueChange();
  }

  private handleFormsValueChange(): void {
    this.points.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.serverError = null;
    });

    this.loyaltyUserOptions$ = this.searchInput$.pipe(
      map((a) => a),
      auditTime(300),
      filter((v) => !!v && v.length >= this.searchMinLength),
      distinctUntilChanged(),
      switchMap((searchText: string) => {
        return this.getLoyaltyCardUsers(searchText).pipe(map((result) => result.users));
      })
    );

    this.searchControl.valueChanges.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged()).subscribe((uniqueCode) => {
      this.loyaltyUser = this.loyaltyUsers.find((u) => u.uniqueCode === uniqueCode);
    });
  }

  private getLoyaltyCardUsers(searchText: string): Observable<LoyaltyCardUsersSearchResultDto> {
    const postData = <LoyaltyCardUsersSearchDto>{
      locationId: this.locationId,
      searchText: searchText?.trim()
    };

    return this.loyaltyCardService.getLoyaltyCardUsers(postData).pipe(
      catchError(() => of({ users: [], loyaltycard: null })),
      tap((result) => {
        this.onCardSearchChange(result);
        if (this.loyaltyCard) {
          this.onSelectChanged(this.loyaltyCard);
        }
      })
    );
  }

  private onCardSearchChange(result: LoyaltyCardUsersSearchResultDto): void {
    if (result?.loyaltycard) {
      this.loyaltyCard = result.loyaltycard;
      this.loyaltyUsers = result.users;
    } else {
      this.nothingFound = true;
    }
  }

  private setLoyaltyCards(): void {
    this.loading = true;

    forkJoin([
      this.locationService.getAll(),
      this.loyaltyCardService.getAccountLoyaltyCard().pipe(catchError(() => NULL_OBSERVABLE))
    ]).subscribe(([locations, loyaltyCard]) => {
      this.currentLocation = locations.find((l) => l.id === this.locationId);

      if (loyaltyCard) {
        this.onSelectChanged(loyaltyCard);
      }

      this.applyAutoSearch();
    });
  }

  private applyAutoSearch(): void {
    if (this.data?.userCardCode) {
      this.searchByCode(this.data.userCardCode);
    } else {
      this.loading = false;
    }
  }

  private resetPointsForm() {
    this.points.setValue("");
    this.clearPointsFormValidators();
  }

  private clearPointsFormValidators(): void {
    this.points.markAsUntouched();
    this.points.updateValueAndValidity();
  }

  private searchByCode(code: string): void {
    this.searchControl.setValue(code, { emitEvent: false });
    this.isAutoSearch = true;
    this.search();
  }
}
