import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { FeatureModalBaseComponent } from "@app-cmc/features/feature-modal-base.component";
import { FeatureTypes } from "@app-cmc/models";
import { CouponSessionUser, UpdateCouponSession } from "@app-cmc/models/coupons";
import { CouponInfo } from "@app-cmc/models/coupons/coupon-info";
import { CouponSession } from "@app-cmc/models/coupons/coupon-session";
import { CouponSessionStatus } from "@app-cmc/models/coupons/coupon-session-status";
import { CFSConnectionService, CompanyDataService } from "@app-cmc/services";
import { CouponService } from "@app-cmc/services/coupon.service";
import { ToastService } from "@app-cmc/shared/components/app-toaster";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map, take, takeUntil, tap } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";

function invalidCouponAsyncValidator(invalidCoupon$: Observable<boolean>): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    return invalidCoupon$.pipe(
      map((invalidCoupon) => (invalidCoupon ? { invalidCoupon: true } : null)),
      take(1)
    );
  };
}

@Component({
  selector: "app-validate-coupon-modal",
  templateUrl: "./validate-coupon-modal.component.html",
  styleUrls: ["./validate-coupon-modal.component.scss"]
})
export class ValidateCouponModalComponent extends FeatureModalBaseComponent implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject();
  private invalidCouponSrc = new BehaviorSubject<boolean>(false);

  invalidCoupon$ = this.invalidCouponSrc.asObservable();
  isValidated = false;
  isCouponUsed = false;
  loading = false;
  coupon: CouponInfo;
  couponStatus: CouponSessionStatus;
  couponSession: CouponSession;
  user: CouponSessionUser;

  form = new FormGroup({
    code: new FormControl("", [Validators.required, Validators.minLength(5)], [invalidCouponAsyncValidator(this.invalidCoupon$)])
  });

  get codeControl(): FormControl<string> {
    return this.form.controls.code;
  }

  constructor(
    public couponSvc: CouponService,
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    public toastSvc: ToastService,
    private companyDataService: CompanyDataService,
    private cfsService: CFSConnectionService,
    private translocoService: TranslocoService
  ) {
    super(activeModal, FeatureTypes.ValidateCoupon);
  }

  ngOnInit(): void {
    this.form.controls.code.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.invalidCouponSrc.next(false);
      this.form.controls.code.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }

  search() {
    if (!this.codeControl.valid) {
      return;
    }

    this.setLoading(true);
    this.invalidCouponSrc.next(false);
    this.form.controls.code.updateValueAndValidity();
    this.isCouponUsed = false;

    this.couponSvc.getCouponSessionByCode(this.form.controls.code.value).subscribe(
      (couponSes) => {
        if (couponSes) {
          this.setCouponInfo(couponSes);
        } else {
          this.setCouponInfo();
          this.invalidCouponSrc.next(true);
          this.form.controls.code.updateValueAndValidity({ emitEvent: false });
        }

        this.setLoading(false);
        this.cdr.detectChanges();
      },
      () => {
        this.setCouponInfo();
        this.invalidCouponSrc.next(true);
        this.form.controls.code.updateValueAndValidity({ emitEvent: false });

        this.setLoading(false);
      }
    );
  }

  private setCouponInfo(couponSes: CouponSession = null): void {
    const { campaign, status, user } = couponSes || {};

    this.couponSession = couponSes;
    this.coupon = campaign;
    this.couponStatus = status;
    this.user = user;
    this.isCouponUsed = !!+status?.validated;
  }

  scan(code: string) {
    this.onScanModalClose();

    if (code && code.startsWith("http")) {
      code = code.split("/").pop();
    }
    this.form.controls.code.setValue(code);

    if (!this.form.controls.code.valid) {
      this.setCouponInfo();
    }

    this.search();
  }

  validate() {
    if (this.couponSession) {
      const updateCouponSession = new UpdateCouponSession();
      updateCouponSession.couponsession = this.couponSession.session;

      this.setLoading(true);

      this.couponSvc.validateCoupon(updateCouponSession).subscribe(
        (res) => {
          if (res.operation === "validate_successful") {
            this.isValidated = true;
            this.isCouponUsed = true;
            this.toastSvc.success(this.translocoService.translate("features.validateCoupon.couponValidated"));
          }
          this.companyDataService.retriveData();
          this.setLoading(false);
        },
        () => {
          this.setLoading(false);
        }
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onScanModalOpened(): void {
    this.cfsService.toggleScanQrCodeModal(true).pipe(take(1)).subscribe();
  }

  private setLoading(loading: boolean): void {
    this.loading = loading;
  }

  private onScanModalClose(): void {
    this.cfsService
      .toggleScanQrCodeModal(false)
      .pipe(take(1))
      .subscribe(() => {});
  }
}
