import {
  AssetParamKeys,
  CfsContentAsset,
  CfsQrCodeContentAsset,
  ContentSettingKeys,
  KeyValueAsset,
  KeyValueDto,
  QrCodeContentSettings,
  TemplateCustomDestinationDto,
  TemplateDestinationDto
} from "../../models/cfs-template";

type ContentAssetByKeys = {
  [key in AssetParamKeys]: string;
};

enum LetterWidthCategory {
  narrow = "narrow",
  medium = "medium",
  wide = "wide"
}

export class CfsTemplateUtils {
  static backgroundImageKey = "%%BACKGROUND_IMAGE%%";
  static contentAssetByKeys: ContentAssetByKeys = {
    [AssetParamKeys.MainBackgroundColor]: ContentSettingKeys.BackgroundColor,
    [AssetParamKeys.ButtonBackgroundColor]: ContentSettingKeys.ButtonColor,
    [CfsContentAsset.MainBackgroundColorHex]: "",
    [CfsContentAsset.ButtonBackgroundColorHex]: "",
    [AssetParamKeys.Param1]: "",
    [AssetParamKeys.Param2]: ContentSettingKeys.CampaignSchedule,
    [AssetParamKeys.Param3]: ContentSettingKeys.TemplateCategory,
    [AssetParamKeys.Param4]: "",
    [AssetParamKeys.Param5]: "",
    [AssetParamKeys.Param6]: ""
  };

  static hexToRgb(hex: any) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m: any, r: any, g: any, b: any) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null;
  }

  static getLottieColorByRgb(rgb: any): string {
    return Object.keys(rgb)
      .map((key: string) => {
        const rgbItem = rgb[key];
        const lottieColorPart = rgbItem ? rgbItem / 255 : 0;
        return `${lottieColorPart}`;
      })
      .join(",");
  }

  static rgbToHex(rgbArray: [number, number, number]): string {
    const hexComponents = rgbArray.map((value) => {
      const hex = (255 * value).toString(16);
      return hex.length === 1 ? `0${hex}` : hex;
    });

    return hexComponents?.length ? `#${hexComponents.join("")}` : "";
  }

  static getLottieColorByHex(hex: string): string {
    const rgb = CfsTemplateUtils.hexToRgb(hex);

    return rgb ? CfsTemplateUtils.getLottieColorByRgb(rgb) : "";
  }

  static getLottieColorListByHex(hex: string): string {
    return `[${CfsTemplateUtils.getLottieColorByHex(hex)}]`;
  }

  static getValueWithFirstUpperLetter(value: string): string {
    if (value && typeof value === "string") {
      const firstLetter: string = value.slice(0, 1).toUpperCase();
      const restValue: string = value.slice(1);

      return `${firstLetter}${restValue}`;
    }

    return "";
  }

  static getKeyValueAsset(params: KeyValueDto[] = []): KeyValueAsset {
    return params?.reduce((acc: KeyValueAsset, item: KeyValueDto) => {
      const { key, value } = item;
      acc[key] = value;

      return acc;
    }, {} as KeyValueAsset);
  }

  static getQrCodeContentAsset(qrCodeContentSettings: QrCodeContentSettings): KeyValueAsset {
    if (!qrCodeContentSettings) return {};

    return Object.keys(qrCodeContentSettings).reduce((acc: KeyValueAsset, key: string) => {
      const assetKey = key as keyof typeof CfsQrCodeContentAsset;

      if (CfsQrCodeContentAsset[assetKey]) {
        acc[CfsQrCodeContentAsset[assetKey]] = CfsTemplateUtils.serializeQrCodeSettingValue(assetKey, qrCodeContentSettings[assetKey]);
      }

      return acc;
    }, {} as KeyValueAsset);
  }

  static parseQrCodeContentAsset(templateDefaultParameters: KeyValueAsset): QrCodeContentSettings {
    if (!templateDefaultParameters) return {};

    const cfsQrCodeContentAssetEnumKeyByValue: { [key: string]: keyof typeof CfsQrCodeContentAsset } = {};

    for (const key in CfsQrCodeContentAsset) {
      if (CfsQrCodeContentAsset.hasOwnProperty(key)) {
        const assetKey = key as keyof typeof CfsQrCodeContentAsset;
        cfsQrCodeContentAssetEnumKeyByValue[CfsQrCodeContentAsset[assetKey]] = assetKey;
      }
    }

    return Object.keys(templateDefaultParameters).reduce((acc: QrCodeContentSettings, key: string) => {
      const enumKey = cfsQrCodeContentAssetEnumKeyByValue[key];
      if (enumKey) {
        (acc[enumKey] as QrCodeContentSettings[keyof QrCodeContentSettings]) = CfsTemplateUtils.parseQrCodeSettingValue(
          enumKey,
          templateDefaultParameters[key]
        );
      }
      return acc;
    }, {} as QrCodeContentSettings);
  }

  static parseQrCodeSettingValue(key: keyof QrCodeContentSettings, value: string): QrCodeContentSettings[keyof QrCodeContentSettings] {
    switch (key) {
      case "backgroundColor":
      case "buttonTextColor":
      case "buttonBackgroundColor":
      case "subtitleColor":
      case "qrActionColor":
      case "qrCodePrimaryColor":
      case "qrCodeSecondaryColor":
      case "qrCodeBackground":
      case "titleColor": {
        return CfsTemplateUtils.rgbToHex(JSON.parse(value));
      }
      case "qrCodeHasFrame": {
        return value === "true";
      }
      case "buttonWidth":
      case "qrCodeLogoType": {
        return +value;
      }
      default:
        return value;
    }
  }

  static serializeQrCodeSettingValue(key: keyof QrCodeContentSettings, value: QrCodeContentSettings[keyof QrCodeContentSettings]): string {
    switch (key) {
      case "backgroundColor":
      case "buttonTextColor":
      case "buttonBackgroundColor":
      case "subtitleColor":
      case "qrActionColor":
      case "qrCodePrimaryColor":
      case "qrCodeSecondaryColor":
      case "qrCodeBackground":
      case "titleColor": {
        return CfsTemplateUtils.getLottieColorListByHex(value as string);
      }
      case "qrCodeHasFrame":
      case "qrCodeLogoType": {
        return value ? value.toString() : "";
      }
      case "buttonWidth": {
        return value ? `${+value}` : "";
      }
      default:
        return value as string;
    }
  }

  static parseQrCodeBackgroundColor(defaultParameters?: KeyValueAsset): string {
    if (defaultParameters && defaultParameters[CfsQrCodeContentAsset.backgroundColor]) {
      return CfsTemplateUtils.parseQrCodeSettingValue(
        "backgroundColor",
        defaultParameters[CfsQrCodeContentAsset.backgroundColor]
      ) as string;
    }
    return "";
  }

  static GetDestination(
    triggerDestination?: TemplateCustomDestinationDto,
    defaultDestination?: TemplateDestinationDto
  ): TemplateDestinationDto | undefined {
    if (!triggerDestination || triggerDestination.useDefaultDestination) return defaultDestination;
    return triggerDestination.customDestination;
  }

  static getLottieTextWidth(text: string): number {
    let width = 0;

    for (let i = 0; i < text.length; i++) {
      width += CfsTemplateUtils.getLetterWidth(text[i]);
    }

    return width;
  }

  private static getLetterWidth(char: string): number {
    const letterWidthCategory = CfsTemplateUtils.getLetterWideGroup(char);

    switch (letterWidthCategory) {
      case LetterWidthCategory.narrow:
        return 4;
      case LetterWidthCategory.medium:
        return 10;
      case LetterWidthCategory.wide:
        return 15.9;
    }
  }

  private static getLetterWideGroup(char: string): LetterWidthCategory {
    switch (char) {
      case "!":
      case "f":
      case "i":
      case "j":
      case "l":
      case "r":
      case "t":
      case "I":
      case "J": {
        return LetterWidthCategory.narrow;
      }
      case "%":
      case "m":
      case "w":
      case "M":
      case "W": {
        return LetterWidthCategory.wide;
      }
      default: {
        return LetterWidthCategory.medium;
      }
    }
  }
}
