<div
  class="coupon {{ type }}"
  [class.selected]="item == selectedCoupon && isActive"
  [class.blured]="selectedCoupon && item !== selectedCoupon"
  (click)="clickSelectCoupon(item)"
  [class.not-active]="!isActive"
>
  <div
    class="coupon-content"
    [class.p-2]="type === 'simple'"
    [ngClass]="!isActive ? 'content-not-active-tooltip' : 'content-active-tooltip'"
    tooltipClass="not-active-tooltip"
    container="body"
    adaptivePosition="true"
    [ngbTooltip]="!isActive ? (inactiveCouponTooltip | transloco) : ''"
  >
    <app-qr-code-preview
      *ngIf="type === 'full'"
      class="coupon-row qr-code-generator-btn"
      [settings]="qrCodeSettings$ | async"
      [disabled]="!isActive"
      (clickButton)="$event.stopPropagation(); setCompleteQrCodeSettings()"
      (openModal)="onCloseRestModals()">
    </app-qr-code-preview>

    <div class="coupon-wrapper position-relative" [class.mb-3]="type === 'full'" [class.d-flex]="type === 'simple'">
      <div *ngIf="!isActive" class="position-absolute top-0 end-0 warning font-size-20 mt-2">
        <i class="mdi dripicons-warning text-warning"></i>
      </div>

      <div class="coupon-row coupon-image" [ngStyle]="{ 'background-image': 'url(' + item.couponImage + ')' }"></div>

      <div *ngIf="item.name" class="coupon-label">
        <div *ngIf="type === 'full'">
          <div class="coupon-row coupon-text coupon-name" [innerHTML]="item.name"></div>
          <div class="coupon-row coupon-text coupon-title" [innerHTML]="item.title"></div>
        </div>

        <div *ngIf="type === 'simple'" class="mx-3">
          <div class="coupon-row coupon-title" [innerHTML]="item.title"></div>
          <div class="coupon-row coupon-name" [innerHTML]="item.name"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type === 'simple'" class="qr-code-preview">
  <app-qr-code-preview
    class="coupon-row qr-code-generator-btn simple"
    withCompactPreview="true"
    [settings]="qrCodeSettings$ | async"
    [disabled]="!isActive"
    (openModal)="onCloseRestModals()"
    (clickButton)="setCompleteQrCodeSettings()"
    (contentLoaded)="qrCodePreviewLoaded($event)">
  </app-qr-code-preview>
</div>
