import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AccountService } from "@app-cmc/core";
import { AllowedDeliveryChannel, CouponInfo, QRCodeSettings, RoleTypes, WidgetMessageInfo, WidgetMessageResultDto } from "@app-cmc/models";
import { QrCodeService, WidgetService } from "@app-cmc/services";
import { ToastService } from "@app-cmc/shared";
import { BehaviorSubject } from "rxjs";
import { delay, mergeMap, take, tap } from "rxjs/operators";
import { TranslocoService } from "@ngneat/transloco";

@Component({
  selector: "app-qr-code-coupon",
  templateUrl: "./qr-code-coupon.component.html",
  styleUrls: ["./qr-code-coupon.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QrCodeCouponComponent implements OnInit {
  @Input() type: "simple" | "full" = "full";

  @Input() set couponInfo(info: CouponInfo) {
    this.item = info;
    this.isActive = this.item?.status === "active";
  }

  @Input() selectedCoupon: CouponInfo;

  @Output() qrCodeSessionId = new EventEmitter<string>();
  @Output() setLoading = new EventEmitter<boolean>();
  @Output() selectCoupon = new EventEmitter<CouponInfo>();
  @Output() closeRestModals = new EventEmitter<null>();
  @Output() contentLoaded = new EventEmitter<boolean>();

  private readonly showContentDelay = 1000;
  private isSales = this.accountSvc.user.role === RoleTypes.Sales;

  item: CouponInfo;
  isActive = false;
  inactiveCouponTooltip = this.isSales ? "features.issueCoupon.notActiveCouponSales" : "features.issueCoupon.notActiveCoupon";
  qrCodeSettings$ = new BehaviorSubject<QRCodeSettings>(null);

  constructor(
    private widgetService: WidgetService,
    private toastSvc: ToastService,
    private accountSvc: AccountService,
    private qrCodeService: QrCodeService,
    private translateSvc: TranslocoService
  ) {}

  ngOnInit(): void {
    this.isSales = this.accountSvc.user.role === RoleTypes.Sales;

    if (this.type === "simple") {
      this.startLoading();
      const messageInfo: WidgetMessageInfo = this.widgetService.getWidgetMessageInfo({ deliveryMethod: AllowedDeliveryChannel.qr });

      this.widgetService
        .getCouponWidgetMessage({ messageType: "start", messageInfo }, this.item)
        .pipe(
          tap(
            (message: WidgetMessageResultDto) => {
              const qrCodeSettings: QRCodeSettings = this.qrCodeService.getInitialQRCodeSettings(message);
              this.qrCodeSettings$.next(qrCodeSettings);
              this.qrCodeSessionId.emit(message.sessionId);
            },
            () => {
              this.toastSvc.danger(this.translateSvc.translateObject("features.errorNotification.qrCodePreview"));
              this.finishLoading();
            }
          ),
          delay(this.showContentDelay),
          take(1)
        )
        .subscribe(
          () => this.contentLoaded.emit(true),
          () => this.contentLoaded.emit(true)
        );
    }
  }

  qrCodePreviewLoaded(isLoaded: boolean): void {
    if (isLoaded) {
      this.finishLoading();
    }
  }

  clickSelectCoupon(coupon: CouponInfo): void {
    if (this.type === "full" && this.isActive) {
      this.selectCoupon.emit(coupon);
    }
  }

  setCompleteQrCodeSettings() {
    this.startLoading();
    const startMessageInfo: WidgetMessageInfo = this.widgetService.getWidgetMessageInfo({
      deliveryMethod: AllowedDeliveryChannel.qr,
      sessionId: this.qrCodeSettings$.value?.sessionId
    });

    if (this.type === "full") {
      this.widgetService
        .getCouponWidgetMessage({ messageType: "start", messageInfo: startMessageInfo }, this.item)
        .pipe(
          mergeMap((message: WidgetMessageResultDto) => {
            const qrCodeSettings: QRCodeSettings = this.qrCodeService.getInitialQRCodeSettings(message);
            this.qrCodeSettings$.next(qrCodeSettings);
            const completeMessageInfo: WidgetMessageInfo = { ...startMessageInfo, sessionId: message.sessionId };

            return this.widgetService.getCouponWidgetMessage(
              { widgetAction: "generateQrCode", messageType: "complete", messageInfo: completeMessageInfo },
              this.item
            );
          }),
          take(1)
        )
        .subscribe(
          (message: WidgetMessageResultDto) => {
            const qrCodeSettings: QRCodeSettings = this.qrCodeService.getFinalQRCodeSettings(message, this.qrCodeSettings$.value);
            this.qrCodeSettings$.next(qrCodeSettings);
            this.finishLoading();
          },
          () => {
            this.toastSvc.danger(this.translateSvc.translateObject("features.errorNotification.qrCode"));
            this.finishLoading();
          }
        );
    } else {
      this.widgetService
        .getCouponWidgetMessage({ messageType: "complete", messageInfo: startMessageInfo }, this.item)
        .pipe(take(1))
        .subscribe(
          (message: WidgetMessageResultDto) => {
            const qrCodeSettings: QRCodeSettings = this.qrCodeService.getFinalQRCodeSettings(message, this.qrCodeSettings$.value);
            this.qrCodeSettings$.next(qrCodeSettings);
            this.finishLoading();
          },
          () => {
            this.toastSvc.danger(this.translateSvc.translateObject("features.errorNotification.qrCode"));
            this.finishLoading();
          }
        );
    }
  }

  onCloseRestModals(): void {
    this.closeRestModals.emit(null);
  }

  private startLoading(): void {
    this.setLoading.emit(true);
  }

  private finishLoading(): void {
    this.setLoading.emit(false);
  }
}
