import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewChild
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { BehaviorSubject } from "rxjs";

export interface CustomCarouselConfig {
  multipleItems: boolean;
  itemWidth: number;
  loop?: boolean;
}

@Component({
  selector: "lib-custom-carousel",
  templateUrl: "./custom-carousel.component.html",
  styleUrls: ["./custom-carousel.component.scss"],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomCarouselComponent<T> implements AfterViewInit {
  @Input() items: T[] = [];
  @Input() config: CustomCarouselConfig = { multipleItems: false, itemWidth: 200, loop: false };
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any> | null = null;
  @ViewChild("carouselInner") carouselInner?: ElementRef;

  private readonly itemsCssGap = 16;
  private readonly mobileScreenWidth = 576;
  private readonly itemCssQuery = ".carousel-item";
  private readonly innerItemCssQuery = ".carousel-item-inner";

  currentIndex = 0;
  itemsPerSlide = 1;
  maxHeight = 0;
  chunkedItems$ = new BehaviorSubject<T[][]>([]);
  isPreviousButtonDisabled = false;
  isNextButtonDisabled = false;

  @HostListener("window:resize") onResize(): void {
    this.currentIndex = 0;

    this.calculateItemsPerSlide();
    this.updateChunkedItems();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.calculateItemsPerSlide();
    this.updateChunkedItems();
    this.changeDetectorRef.detectChanges();
  }

  goToPreviousSlide(): void {
    if (this.config.loop) {
      this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
    } else {
      this.currentIndex = Math.max(this.currentIndex - 1, 0);
    }

    this.updateActiveItem();
  }

  goToNextSlide(): void {
    if (this.config.loop) {
      this.currentIndex = (this.currentIndex + 1) % this.items.length;
    } else {
      this.currentIndex = Math.min(this.currentIndex + 1, Math.ceil(this.items.length / this.itemsPerSlide) - 1);
    }

    this.updateActiveItem();
  }

  private calculateItemsPerSlide(): void {
    const carouselWidth = this.carouselInner?.nativeElement.offsetWidth;

    if (this.config.multipleItems && window.innerWidth >= this.mobileScreenWidth) {
      const itemWidthWithGap = this.config.itemWidth + this.itemsCssGap;
      this.itemsPerSlide = Math.floor(carouselWidth / itemWidthWithGap);
    } else {
      this.itemsPerSlide = 1;
    }

    if (this.carouselInner) {
      this.carouselInner.nativeElement.style.setProperty("--items-per-slide", this.itemsPerSlide.toString());
      this.carouselInner.nativeElement.style.setProperty("--item-width", `${this.config.itemWidth}px`);
    }

    this.updateActiveItem();
  }

  private updateChunkedItems(): void {
    const chunkedItems: T[][] = [];
    const itemsLength = this.items.length;

    if (this.config.loop) {
      for (let i = 0; i < itemsLength; i++) {
        const chunk = [];
        for (let j = 0; j < this.itemsPerSlide; j++) {
          chunk.push(this.items[(i + j) % itemsLength]);
        }
        chunkedItems.push(chunk);
      }
    } else {
      for (let i = 0; i < itemsLength; i += this.itemsPerSlide) {
        chunkedItems.push(this.items.slice(i, i + this.itemsPerSlide));
      }
    }

    this.chunkedItems$.next(chunkedItems);
    this.calculateMaxHeight();
  }

  private calculateMaxHeight(): void {
    setTimeout(() => {
      const carouselItems = document.querySelectorAll(this.itemCssQuery);
      const originalDisplayStyles: string[] = [];

      carouselItems.forEach((item, index) => {
        originalDisplayStyles[index] = (item as HTMLElement).style.display;
        (item as HTMLElement).style.display = "block";
      });

      const items = document.querySelectorAll(this.innerItemCssQuery);
      this.maxHeight = Array.from(items).reduce((max: number, item: Element) => {
        const height: number = item ? (item as HTMLElement).offsetHeight : 0;

        return height > max ? height : max;
      }, 0);

      carouselItems.forEach((item, index) => {
        (item as HTMLElement).style.display = originalDisplayStyles[index];
      });

      if (this.carouselInner) {
        this.carouselInner.nativeElement.style.setProperty("--max-item-height", `${this.maxHeight}px`);
      }
    });
  }

  private updateActiveItem(): void {
    const items = document.querySelectorAll(".carousel-item");

    items.forEach((item, index) => {
      item.classList.toggle("active", Math.floor(index / this.itemsPerSlide) === this.currentIndex);
    });

    this.setActionButtonsDisabled();
  }

  setActionButtonsDisabled(): void {
    this.isPreviousButtonDisabled = !this.config.loop && this.currentIndex === 0;
    this.isNextButtonDisabled = !this.config.loop && this.currentIndex === Math.ceil(this.items.length / this.itemsPerSlide) - 1;
  }
}
