import { Injectable } from "@angular/core";
import { DataService } from "@app-cmc/core/services/data.service";
import {
  LoyaltyCard,
  LoyaltyCardUpdateAmount,
  LoyaltyCardUpdateResult,
  LoyaltyCardUsersSearchResultDto,
  LoyaltyCardUsersSearchDto
} from "@app-cmc/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class LoyaltyCardService {
  private serviceUrl = "LoyaltyCard";

  constructor(private http: DataService) {}

  getAccountLoyaltyCard(accountId?: number): Observable<LoyaltyCard> {
    let url = `${this.serviceUrl}/account`;
    if (accountId) url += `/${accountId}`;
    return this.http.get(url);
  }

  addUserLoyaltyCardAmount(update: LoyaltyCardUpdateAmount): Observable<LoyaltyCardUpdateResult> {
    return this.http.post(`${this.serviceUrl}/addUserAmount`, update).pipe(
      map((result: LoyaltyCardUpdateResult) => {
        return result;
      })
    );
  }
  getLoyaltyCardUsers(search: LoyaltyCardUsersSearchDto): Observable<LoyaltyCardUsersSearchResultDto> {
    return this.http.post(`${this.serviceUrl}/loyaltyCardUsers`, search).pipe(
      map((card: LoyaltyCardUsersSearchResultDto) => {
        return card;
      })
    );
  }
}
