import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[libIframeButtonPostMessage]",
  standalone: true
})
export class IframeButtonPostMessageDirective {
  @Input() buttonId = "";

  @HostListener("click") onClick(): void {
    if (this.isIframe()) {
      this.sendPostMessage("buttonClick", this.buttonId);
    }
  }

  private isIframe(): boolean {
    return window.self !== window.top;
  }

  private sendPostMessage(eventName: string, buttonId: string): void {
    const targetWindow = window.top;

    if (targetWindow) {
      const data = { type: eventName, buttonId };

      targetWindow.postMessage(data, "*");
    }
  }
}
