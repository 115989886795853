export interface ContentSettings {
  assetId?: number;
  isPredefined: boolean;
  templateCategory: string;
  displayContent: string;
  backgroundColor: string;
  buttonColor: string;
  campaignSchedule: string;
}

export interface UrlContentSettings {
  ContentType: CustomContentTemplateType;
  ImageUrl: string;
  VideoUrl?: string;
  ContentDuration?: number;
}

export interface CouponContentSettings {
  CouponUrl: string;
  CouponName: string;
  CouponId: string;
}

export interface TemplateExtendedProperties {
  LottieJson?: string;
  CouponName?: string;
  ShortLinkUrl?: string;
  LocationId?: string;
}

export interface MediaContent {
  id: number;
  name: string;
  title: string;
  mediaContentType: MediaContentType;
  configuration: { defaultParameters: KeyValueDto[] };
  digitalAssets: DigitalAsset[];
}

export class DigitalAsset {
  public id?: number;
  public mediaContentId?: number;
  public name?: string;
  public content?: string;
  public title?: string;
  public contentUrl?: string;
  public digitalAssetType?: DigitalAssetType;
  public contentType?: string;
  public configuration?: {
    defaultParameters: KeyValueDto[];
    screenSize: ScreenSizeType;
  };
}

export interface TemplateBasePreviewOptions {
  digitalAsset?: DigitalAsset;
  json?: string;
  screenSizesJsonAsset?: ScreenSizesJsonAsset;
  mediaContent?: MediaContent;
}

export interface TemplatePreviewOptions extends TemplateBasePreviewOptions {
  htmlBody?: string;
  previewTitle?: string;
  previewIcon?: string;
  contentSettings?: ContentSettings;
  defaultParameters?: KeyValueAsset;
}

export interface QrCodeContentSettings {
  backgroundColor?: string;
  backgroundImage?: string;
  logoUrl?: string;
  imageUrl?: string;
  qrCodePrimaryColor?: string;
  qrCodeSecondaryColor?: string;
  qrCodeBackground?: string;
  qrCodeHasFrame?: boolean;
  qrCodeLogo?: string;
  qrCodeLogoType?: DisplayQrCodeLogoTypes;
  qrCodeUrl?: string;
  buttonText?: string;
  buttonWidth?: number;
  buttonTextColor?: string;
  buttonBackgroundColor?: string;
  qrActionText?: string;
  qrActionColor?: string;
  subtitleText?: string;
  subtitleColor?: string;
  titleText?: string;
  titleColor?: string;
}

export interface TemplateDestinationDto {
  destinationType: TemplateDestinationType;
  separateUrl?: boolean;
  url?: string;
  widgetType?: number;
  externalCouponId?: string;
}
export interface TemplateCustomDestinationDto {
  useDefaultDestination: boolean;
  customDestination?: TemplateDestinationDto;
}

export interface KeyValueDto {
  key: string;
  value: string;
}

export interface KeyValueAsset {
  [key: string]: string;
}

export interface CfsTemplateMediaConfig {
  autoplay?: boolean;
  muted?: boolean;
}

export type ScreenSizesJsonAsset = {
  [size in ScreenSizeType]?: string;
};

export enum DigitalAssetType {
  Lottie,
  Html
}

export enum DisplayQrCodeLogoTypes {
  None = 0,
  BrandLogo = 1,
  CustomLogo = 2,
  DisplayQrCodeLogo = 3
}

export enum ScreenSizeType {
  Compact = "Compact",
  Full = "Full",
  Vertical = "Vertical"
}

export enum AssetParamKeys {
  MainBackgroundColor = "%%MAIN_BG_COLOR%%",
  ButtonBackgroundColor = "%%BUTTON_BG_COLOR%%",
  MainBackgroundColorHex = "%%MAIN_BG_COLOR_HEX%%",
  ButtonBackgroundColorHex = "%%BUTTON_BG_COLOR_HEX%%",
  Param1 = "%%PARAM_1%%",
  Param2 = "%%PARAM_2%%",
  Param3 = "%%PARAM_3%%",
  Param4 = "%%PARAM_4%%",
  Param5 = "%%PARAM_5%%",
  Param6 = "%%PARAM_6%%"
}

export enum ContentSettingKeys {
  BackgroundColor = "backgroundColor",
  ButtonColor = "buttonColor",
  CampaignSchedule = "campaignSchedule",
  TemplateCategory = "templateCategory"
}

export enum CfsContentAsset {
  MainBackgroundColor = "%%MAIN_BG_COLOR%%",
  ButtonBackgroundColor = "%%BUTTON_BG_COLOR%%",
  MainBackgroundColorHex = "%%MAIN_BG_COLOR_HEX%%",
  ButtonBackgroundColorHex = "%%BUTTON_BG_COLOR_HEX%%",
  Param1 = "%%PARAM_1%%",
  Param2 = "%%PARAM_2%%",
  Param3 = "%%PARAM_3%%",
  Param4 = "%%PARAM_4%%",
  Param5 = "%%PARAM_5%%",
  Param6 = "%%PARAM_6%%"
}

export enum CfsQrCodeContentAsset {
  backgroundColor = "%%BACKGROUND_COLOR%%",
  backgroundImage = "%%BACKGROUND_IMAGE%%",
  logoUrl = "%%LOGO_URL%%",
  imageUrl = "%%IMAGE_URL%%",
  qrCodePrimaryColor = "%%QR_CODE_PRIMARY_COLOR%%",
  qrCodeSecondaryColor = "%%QR_CODE_SECONDARY_COLOR%%",
  qrCodeBackground = "%%QR_CODE_BACKGROUND%%",
  qrCodeHasFrame = "%%QR_CODE_HAS_FRAME%%",
  qrCodeLogo = "%%QR_CODE_LOGO%%",
  qrCodeLogoType = "%%QR_CODE_LOGO_TYPE%%",
  qrCodeUrl = "%%QR_CODE_URL%%",
  buttonText = "%%BUTTON_TEXT%%",
  buttonWidth = '"%%BUTTON_WIDTH%%"',
  buttonTextColor = '"%%BUTTON_TEXT_COLOR%%"',
  buttonBackgroundColor = '"%%BUTTON_BACKGROUND_COLOR%%"',
  qrActionText = "%%QR_ACTION_TEXT%%",
  qrActionColor = '"%%QR_ACTION_COLOR%%"',
  subtitleText = "%%SUBTITLE_TEXT%%",
  subtitleColor = '"%%SUBTITLE_COLOR%%"',
  titleText = "%%TITLE_TEXT%%",
  titleColor = '"%%TITLE_COLOR%%"'
}

export enum MediaContentType {
  CfsTemplate = "CfsTemplate",
  QrCodeTemplate = "QrCodeTemplate",
  CfsQrCodeTemplate = "CfsQrCodeTemplate"
}

export enum TemplateDestinationType {
  Coupon = "Coupon",
  Url = "Url",
  Widget = "Widget"
}

export enum CustomContentTemplateType {
  Image = "Image",
  Video = "Video"
}

export enum TemplatePreviewType {
  Active = "Active",
  Inactive = "Inactive"
}

export enum CFSTemplateType {
  Lottie = "lottie",
  Video = "video",
  Image = "image"
}
